<template>
<div v-loading="true"></div>
</template>
<script>
import api from '../api'
export default {
    mounted() {
        api.logout().then(res => {
            if (res.code != 0) {
                this.$message.error(res.message);
            }
            localStorage.removeItem("token");
            localStorage.removeItem("uid");
            document.cookie = "";
            location.reload();
        }).catch(err => {
            this.$message.error(err.toString());
            console.error(err);
        })
    }
}
</script>